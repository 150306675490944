import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import handlerAuth from './handlerAuth'

Vue.use(VueAxios, axios)
axios.defaults.baseURL = process.env.VUE_APP_API_ROUTE;

Vue.config.productionTip = false

handlerAuth()

Vue.filter('formatNumber', function (num) {
  if (num) {
    num = num.toString().replace(/\,/g, '');
    const formateado = new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(num);
    return '$' + formateado;
  }
  return "0";
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
