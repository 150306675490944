<template lang="pug">
#mainContent.col-12
  div(v-for="gr in CATEGORIAS" :key="gr.id" )
    section(:id="`section${gr.id}`")
      .main-title
        h5 #[i.bi.bi-tag] {{ gr.nombre }}
    .row.menu-gallery      
      .col-6.col-md-3.animated-element(v-for="a in gr.articulos" :key="a.id" :id="`gridItem${a.id}`")
        .item-body
          figure(@click="$store.commit('SET_ORDEN', {art:a})" style="cursor:pointer")
            img.img-fluid(v-if='a.imagen_base64' :src='`data:image/png;base64,${a.imagen_base64}`')
            img.img-fluid(v-else src='/img/plato-comida.jpg')            
            .item-body-link
              .item-title
                h3 {{ a.descripcion_comercial }}
                small 
          ul
            li
              span.item-price                
                span {{ a.valor_sugerido_venta | formatNumber }}
            li: a.add-item-to-cart(href='javascript:void(0);' @click="$store.commit('SET_ORDEN', {art:a})"): i.bi.bi-cart-plus
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['CATEGORIAS'])
  }
}
</script>