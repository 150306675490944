<template lang="pug">
main
  //- Hero
  BannerEmpresa

  //- Sticky Nav
  nav.sticky-nav
    .container
      ul#stickyNavItems
        li(v-for="n in CATEGORIAS" :key="n.id")         
          a.list-group-item.sticky-nice-scroll(:href='`#section${n.id}`' @click="navegarCategoria(`#section${n.id}`, $event)") #[i.bi.bi-tag] {{ n.nombre }}
    span

  //- Main
  .order
    .container.pb-5
      .row
        ListaProductos      
</template>

<script>
import ListaProductos from '@/components/ListaProductos.vue';
import BannerEmpresa from '@/components/BannerEmpresa.vue';

import { mapState } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    BannerEmpresa,
    ListaProductos,
  },

  computed: {
    ...mapState(['CATEGORIAS'])
  },

  methods: {
    navegarCategoria(hash, event) {
      event.preventDefault()
      const posicion = $(hash).offset().top - 140;
      window.scrollTo(0, posicion)
    }
  }
}
</script>
