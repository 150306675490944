import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    DATA_BASE: undefined,
    EMPRESA: {
      ciudad: {
        departamento: {}
      }
    },
    ORDEN: [],
    CATEGORIAS: [],
    MENSAJES: [],
  },
  getters: {
    sizeOrden: (state) => {
      return state.ORDEN.reduce((total, currentItem) => total += currentItem.cantidad, 0);
    }
  },
  mutations: {
    SET_CATEGORIAS(state, rows) {
      state.CATEGORIAS = rows
    },

    SET_MENSAJES(state, rows) {
      state.MENSAJES = rows
    },

    SET_ORDEN(state, payload) {
      const { art, restar } = payload
      let item = {
        cantidad: 1,
        valor_unitario: art.valor_sugerido_venta || art.valor_unitario,
        valor_inicial: art.valor_sugerido_venta || art.valor_inicial,
        valor_parcial: art.valor_sugerido_venta || art.valor_parcial,
        tarifa_impuesto: art.tarifa_impuesto || 0,
        valorImpuesto: 0,
        vlr_descuento: 0,
        tipo_descuento: '',
        base_impuesto: art.valor_sugerido_venta || art.base_impuesto
      }
      if (art.articulo) {
        item.articulo = art.articulo
      } else {
        item.articulo = {
          id: art.id,
          codigo: art.codigo,
          descripcion_comercial: art.descripcion_comercial,
          imagen_base64: art.imagen_base64
        }
      }
      if (art.impuesto_generado) {
        item.tarifa_impuesto = art.impuesto_generado.tarifa
      }
      item.base_impuesto = item.valor_parcial / (1 + (item.tarifa_impuesto / 100))
      item.valorImpuesto = item.base_impuesto * (item.tarifa_impuesto / 100)

      if (restar) {
        item.cantidad = -1
      }
      const id_entrante = art.id || art.articulo.id
      let item_existe = state.ORDEN.find(it => it.articulo.id == id_entrante)
      if (item_existe) {
        item_existe.cantidad += item.cantidad
        item_existe.valor_parcial = item_existe.valor_unitario * item_existe.cantidad
        item_existe.base_impuesto = item_existe.valor_parcial / (1 + (item_existe.tarifa_impuesto / 100))
        item_existe.valorImpuesto = item.base_impuesto * (item_existe.tarifa_impuesto / 100)
        const index = state.ORDEN.findIndex(it => it.articulo.id == id_entrante)
        if (item_existe.cantidad <= 0) {
          state.ORDEN.splice(index, 1)
        } else {
          state.ORDEN.splice(index, 1, item_existe)
        }
      } else {
        state.ORDEN.push(item)
      }

      localStorage.setItem("ORDEN" + state.DATA_BASE, JSON.stringify(state.ORDEN))
    },

    QUIT_ORDEN(state, item) {
      const index = state.ORDEN.findIndex(it => it.articulo.id == item.articulo.id)
      state.ORDEN.splice(index, 1)
      localStorage.setItem("ORDEN" + state.DATA_BASE, JSON.stringify(state.ORDEN))
    },

    EMPTY_ORDEN(state) {
      state.ORDEN = []
      localStorage.setItem("ORDEN" + state.DATA_BASE, JSON.stringify(state.ORDEN))
    }
  },
  actions: {
  },
  modules: {
  }
})
