<template lang="pug">
div#mm-0.mm-page.mm-slideout
  #page
    TheHeader
    router-view

  #toTop(style='display: none'): i.bi.bi-chevron-up
</template>

<script>
import TheHeader from "@/components/TheHeader"

import scripts from "@/assets/scripts-config";

export default {
  name: 'App',
  components: {
    TheHeader
  },

  created() {
    this.axios.get("/go-menu-digital").then(({ data }) => {
      const newMap = data.map((art) => JSON.stringify(art.grupo))
      const categorias = Array.from(new Set(newMap)).map(JSON.parse)
      categorias.forEach(item => {
        item.articulos = data.map(m => {
          if (m.grupo.id == item.id) {
            return m.articulo
          }
        }).filter(art => art)
      })
      this.$store.commit('SET_CATEGORIAS', categorias)
    })
  },

  mounted() {
    scripts()
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },

    eliminaMarcasDuplicadas(arr) {
      const marcasMap = arr.map((marca) => {
        return [marca.id_marca, marca];
      });

      return [...new Map(marcasMap).values()];
    },
  }
}
</script>