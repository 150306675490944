import axios from "axios"
import router from './router'
import store from './store'


export default () => {

  router.onReady(() => {
    const anteriorDB = localStorage.getItem("KEY") || 0
    store.state.DATA_BASE = router.currentRoute.params.database
    localStorage.setItem("KEY", store.state.DATA_BASE)
    if (anteriorDB != store.state.DATA_BASE) {
      localStorage.removeItem('ORDEN' + anteriorDB)
    }
    store.state.ORDEN = JSON.parse(localStorage.getItem("ORDEN" + store.state.DATA_BASE) || '[]')
    axios.interceptors.request.use(config => {
      config.headers.database = store.state.DATA_BASE
      return config
    }, err => {
      return Promise.reject(err) - l
    })

    axios.get('/go-empresa?skip=true').then(({ data }) => {
      // console.log(data);
      store.state.EMPRESA = data
    }).catch((err) => {
      console.dir(err);
    });
  })
}