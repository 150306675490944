// (function ($) {
export default () => {

	// =====================================================
	// PRELOADER
	// =====================================================
	$(window).on("load", function () {
		'use strict';
		$('[data-loader="circle-side"]').fadeOut();
		$('#preloader').delay(350).fadeOut('slow');
		// var $hero = $('.hero-home .content');
		// var $hero_v = $('#hero_video .content ');
		// $hero.find('h3, p, form').addClass('fadeInUp animated');
		// $hero.find('.btn-1').addClass('fadeIn animated');
		// $hero_v.find('.h3, p, form').addClass('fadeInUp animated');
		// $(window).scroll();
	})

	// =====================================================
	// BACK TO TOP BUTTON
	// =====================================================
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 100) {
			$('#toTop').fadeIn('slow');
		} else {
			$('#toTop').fadeOut('slow');
		}
	});

	$('#toTop').on('click', function () {
		window.scrollTo(0, 0)
		return false;
	});

	// document.getElementById('stickyNavItems').addEventListener('click', function (e) {
	// 	const element = e.target
	// 	if (element.tagName == 'A' && element.classList.contains('sticky-nice-scroll')) {
	// 		e.preventDefault()
	// 		// const categoria = document.getElementById(element.hash.replace('#', ''))			
	// 		const posicion = $(element.hash).offset().top - 140;
	// 		window.scrollTo(0, posicion)
	// 	}
	// });

}