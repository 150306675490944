import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:database',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:database/pedido',
    name: 'pedido',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetalleOrdenView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
