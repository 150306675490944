<template lang="pug">
.hero-order(:class="[EMPRESA.imagen_carta_digital ? 'bg-img-custom' : 'bg-img']" :style="EMPRESA.imagen_carta_digital ? { backgroundImage: `url(data:image/png;base64,${EMPRESA.imagen_carta_digital})`}:''")
  .content
    .container.main-info
      .row
        .col-lg-9
          h5.text-white {{ EMPRESA.razon_comercial }}
          .contact-info.d-flex.flex-column
            a(href='#')
              i.bi.bi-geo-alt-fill
              span {{ EMPRESA.direccion }}
            a(href='#')
              i.bi.bi-telephone-fill
              span {{ EMPRESA.telefonos }}
            a(href='#')
              i.bi.bi-envelope-fill
              span {{ EMPRESA.email }}
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({

  }),
  computed: {
    ...mapState(['EMPRESA'])
  },
}
</script>

<style>
.bg-img-custom {
  background: #f25c04 no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>